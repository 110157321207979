<template>
	<div :class="`saveToBriefingButtonContainer ${shouldShow ? 'mr-auto' : ''}`">
    <div v-if="shouldShow">
      <button @click="click()" class="btn add" :disabled="added" v-if="!added">
        <font-awesome-icon icon="print" />
        {{ $t('briefingReport.add') }}
      </button>
      <button v-else class="btn added" @click="goToBriefing()" >
        <font-awesome-icon icon="print" />
        {{ $t('briefingReport.showMyBriefing') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
	name: 'SaveToBriefingButton',
  props: {
    added: Boolean,
		type: String
  },
  computed: {
    shouldShow() {
      return window.platform !== "android" && window.platform !== "ios";
    }
  },
	methods: {
		click() {
			window.newEvent('ADD_TO_BRIEFING', {
				type: this.type
			})
      this.$emit('click')
		},
    goToBriefing() {
      this.$router.push({'path': '/briefing-report'})
		},
	},
	emits: ['click']
}
</script >

<style scoped>
  .saveToBriefingButtonContainer .btn.add {
    background: #FBBD23;
    white-space: nowrap;
    display: block;
  }

  .saveToBriefingButtonContainer .btn.added {
    background: #37CDBE;
    white-space: nowrap;
    display: block;
  }

  .saveToBriefingButtonContainer .btn:hover {
    filter: brightness(95%);
  }


	@media screen and (max-width: 768px) {
		.saveToBriefingButtonContainer .btn {
			font-size: 0.8em;
		}
	}

</style>
